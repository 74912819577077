<template>
    <b-row>
        <b-col lg="12" sm="12">
            <b-overlay :show="loader">
                <b-row>
                    <b-col class="mb-3">
                        <!-- <h5 class="text-center">{{ $i18n.locale == 'en' ? fair_info.fair_name_en : fair_info.fair_name_bn }}</h5> -->
                        <!-- <div class="text-center">{{ $i18n.locale == 'en' ? fair_info.fiscal_year_en : fair_info.fiscal_year_bn }}</div> -->
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-dark mb-3" lg="12" sm="12">
                        <div class="bg-dark w-50 m-auto rounded-pill">
                            <h6 class="text-white text-center py-1"> {{ $t('globalTrans.payment') }}</h6>
                        </div>
                        <div class="text-center mt-2">
                            <b-form-radio-group
                                v-model="payment_type"
                                :options="paymentTypeList"
                            ></b-form-radio-group>
                        </div>
                    </b-col>
                    <b-col lg="12" sm="12">
                        <keep-alive>
                            <component v-bind:is="comp" :id="id" :payment="payment" @clicked="onClickChild"></component>
                        </keep-alive>
                    </b-col>
                </b-row>
                <b-row>
                    <!-- <b-table-simple bordered small>
                        <thead>
                            <tr>
                                <b-th colspan="6" class="text-white text-center p-2 bg-dark">{{ $t('tradeFairConfig.stall_information') }}</b-th>
                            </tr>
                            <tr class="bg-primary text-center text-align-center">
                                <th style="width:10%">{{ $t('globalTrans.sl_no') }}</th>
                                <th style="width:20%">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                                <th style="width:15%">{{ $t('tradeFairConfig.stall_size') }}</th>
                                <th style="width:15%">{{ $t('tradeFairConfig.stall_type') }}</th>
                                <th style="width:15%">{{ $t('tradeFairConfig.booth_rent') + ' ' + $t('globalTrans.tk') }}</th>
                            </tr>
                        </thead>
                        <b-tbody v-if="stalls.length > 0">
                            <b-tr v-for="(item, index) in stalls" :key="index">
                                <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                <b-td>{{ getStallCategoryName(item.stall_cat_id) }}</b-td>
                                <b-td>{{ $n(item.stall_size) + ' ' + getMeasurementUnitName(item.measurement_id) }}</b-td>
                                <b-td class="text-center">{{ getStallType(item.stall_type) }}</b-td>
                                <b-td class="text-right">{{ $n(item.booth_rent, { minimumFractionDigits: 2 }) }}</b-td>
                            </b-tr>
                            <b-tr style="background: #ddd">
                                <b-td class="text-right" colspan="4">{{ $t('sitePreference.total') + ' ' + $t('globalTrans.tk') }}</b-td>
                                <b-td class="text-right">{{ $n(payment.total_amount, { minimumFractionDigits: 2 }) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td class="text-right" colspan="4">{{ $t('tradeFairConfig.subsidy') + ' (%)' }}</b-td>
                                <b-td class="text-right">{{ $n(payment?.subsidy, { minimumFractionDigits: 2 }) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td class="text-right" colspan="4">{{ $t('externalTradeFair.subsidy_amount') + ' ' + $t('globalTrans.tk') }}</b-td>
                                <b-td class="text-right">{{ $n(payment?.subsidy_amount, { minimumFractionDigits: 2 }) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td class="text-right" colspan="4">{{ $t('sitePreference.grandTotal') + ' ' + $t('externalTradeFair.payable') }}</b-td>
                                <b-td class="bg-warning text-right">{{ $n(payment.grand_total, { minimumFractionDigits: 2 }) }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple> -->
                    <!-- <b-col class="text-right">
                        <b-button type="button" @click="setPayment()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.payment') }} <i class="ri-arrow-right-line"></i></b-button>
                        <b-button variant="danger" type="button" class="btn-sm" @click="$bvModal.hide('modal-payment')">{{ $t('globalTrans.cancel') }}</b-button>
                    </b-col> -->
                </b-row>
            </b-overlay>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { stallInformationDetailsApi } from '../../../api/routes'
import PaymentOnline from './PaymentOnline'
import PaymentOffline from './PaymentOffline'

export default {
  name: 'Form',
  props: ['id', 'item'],
  components: {
    PaymentOnline, PaymentOffline
  },
  data () {
    return {
        comp: 'PaymentOnline',
        loader: false,
        fair_info: {},
        stalls: [],
        payment: {},
        is_payment: false,
        payment_type: 1,
        subsidy_stall: 0
    }
  },
  created () {
    if (this.item) {
        this.payment.amount = this.item.registration_fee
        this.payment.circular_memo_no = this.item.circular_memo_no
        this.payment.fiscal_year_id = this.item.fiscal_year_id
        this.payment.application_id = this.item.application_id
    }
  },
  watch: {
    payment_type: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal === 2) {
              this.comp = 'PaymentOffline'
          } else {
              this.comp = 'PaymentOnline'
          }
        }
    }
  },
  computed: {
    paymentTypeList () {
      return [
        { text: this.$i18n.locale === 'bn' ? 'অনলাইন' : 'Online', value: 1 },
        { text: this.$i18n.locale === 'bn' ? 'অফলাইন' : 'Offline', value: 2 }
      ]
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    onClickChild () {
      this.$bvModal.hide('modal-payment')
    },
    async getPayInfo () {
        this.loader = true
        const myObj = {
             participation_id: this.id,
             fair_name_id: this.fair_info.fair_circular.fair_name_id,
             applied_sector_id: this.fair_info.applied_sector_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, stallInformationDetailsApi, myObj)
        this.loader = false
        if (result.success) {
            this.stalls = result.data.stall_list
            this.payment.subsidy = result.data.subsidy
            this.subsidy_stall = result.data.subsidy_stall.stall_detail_id
            this.calculateTotal()
        }
    }
  }
}
</script>
