<template>
    <b-row>
        <b-col lg="12" sm="12" :show="loader">
            <b-overlay :show="loader">
                <list-report-head :base-url="baseUrl" uri="/config/report-head/detail" :org-id="item.org_id">
                  <template v-slot:projectNameSlot>
                    {{ }}
                  </template>
                  <b-row>
                    <b-col>
                        <h6 class="text-center" style="font-weight:bold">{{ getTrainingTitle(form) }}</h6>
                    </b-col>
                  </b-row>
                </list-report-head>
                <b-row>
                    <b-col md="6" class="mb-2 text-center mx-auto">
                        <h5  style="font-weight:bold;background-color:#ddd">{{ $t('globalTrans.payment') + ' ' + $t('globalTrans.receipt') }}</h5>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col md="4" class="mb-2 text-center mx-auto">
                    <p style="font-weight:bold;background-color:green;color: aliceblue;">{{$t('externalTraining.applicant')}} {{$t('globalTrans.info')}}</p>
                  </b-col>
                </b-row>
                <b-row class="mb-3 mx-2 mt-2">
                  <b-col md="3" style="font-weight:bold">{{$t('externalTraining.applicant')}} {{$t('globalTrans.name')}} {{":"}}</b-col>
                  <b-col md="3">{{ ($i18n.locale ==='bn') ? appInfo.personal_info.name_bn : appInfo.personal_info.name }}</b-col>
                  <b-col md="3" style="font-weight:bold">{{$t('globalTrans.organization')}} {{$t('globalTrans.name')}} {{":"}}</b-col>
                  <b-col md="3">{{ getOrgName(1) }}</b-col>
                  <b-col md="3" style="font-weight:bold">{{$t('elearning_config.training_title')}} {{":"}}</b-col>
                  <b-col md="3">{{ getTrainingTitle(appInfo.training_title_id)}}</b-col>
                  <b-col md="3" style="font-weight:bold">{{$t('elearning_config.training_type')}} {{":"}}</b-col>
                  <b-col md="3">{{ getTrainingType(appInfo.training_type_id)}}</b-col>
                </b-row>
                <b-row>
                  <b-col md="4" class="mb-2 text-center mx-auto">
                    <p style="font-weight:bold;background-color:green;color: aliceblue;">{{$t('globalTrans.payment')}} {{$t('globalTrans.info')}}</p>
                  </b-col>
                </b-row>
                <b-row class="mb-3 mx-2 mt-2">
                  <b-col md="3" style="font-weight:bold">{{$t('globalTrans.total')}} {{$t('globalTrans.payment')}} {{":"}}</b-col>
                  <b-col md="3">{{ $n(fair_info.registration_fee)}}</b-col>
                  <b-col md="3" style="font-weight:bold">{{$t('globalTrans.payment_type')}} {{":"}}</b-col>
                  <b-col md="3">{{ getPayType(paymentData.payment_type) }}</b-col>
                  <b-col md="3" style="font-weight:bold" v-if="paymentData.payment_type == 2">{{ $t('globalTrans.voucher_no') }} {{":"}}</b-col>
                  <b-col md="3" v-if="paymentData.payment_type == 2">{{ paymentData.voucher_no }}</b-col>
                  <b-col md="3" style="font-weight:bold">{{ $t('globalTrans.date') }} {{":"}}</b-col>
                  <b-col md="3">{{ paymentData.payment_date | dateFormat }}</b-col>
                  <b-col md="3" style="font-weight:bold" v-if="paymentData.payment_type == 2">{{ $t('sideBar.bank') + ' ' + $t('globalTrans.name')  }} {{":"}}</b-col>
                  <b-col md="3" v-if="paymentData.payment_type == 2">{{ getBankName(paymentData.bank_id) }}</b-col>
                  <b-col md="3" style="font-weight:bold" v-if="paymentData.payment_type == 2">{{ $t('sideBar.branch') + ' ' + $t('globalTrans.name')  }} {{":"}}</b-col>
                  <b-col md="3" v-if="paymentData.payment_type == 2">{{ getBranchName(paymentData.branch_id) }}</b-col>
                </b-row>
            </b-overlay>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { paymentReceiptApi, trainingAppInfoApi } from '../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
export default {
  name: 'Form',
  props: ['id', 'item'],
  components: {
    ListReportHead
  },
  data () {
    return {
        baseUrl: trainingElearningServiceBaseUrl,
        comp: 'PaymentOnline',
        loader: false,
        fair_info: {},
        stallCategoryList: [],
        stalls: [],
        appInfo: {},
        paymentData: {},
        paymentTypeList: [
          { text: this.$i18n.locale === 'bn' ? 'অনলাইন' : 'Online', value: 1 },
          { text: this.$i18n.locale === 'bn' ? 'অফলাইন' : 'Offline', value: 2 }
        ]
    }
  },
  created () {
    if (this.id) {
      this.getAppDetails()
      const tmp = this.getEditData()
      this.fair_info = tmp
      this.getPayInfo()
    }
  },
  watch: {
//
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    async getPayInfo () {
        this.loader = true
        const myObj = {
             circular_memo_no: this.item.circular_memo_no
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, paymentReceiptApi, myObj)
        this.loader = false
        if (result.success) {
            this.paymentData = result.data
        }
    },
    async getAppDetails () {
        this.loader = true
        const params = {
             circular_memo_no: this.item.circular_memo_no
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingAppInfoApi, params)
        this.loader = false
        if (result.success) {
            this.appInfo = result.data
        } else {
            this.appInfo = {}
        }
    },
    getEditData () {
      const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.id))
      return JSON.parse(JSON.stringify(tmpData))
    },
    getPayType (id) {
        const dataPayType = this.paymentTypeList.find(item => item.value === parseInt(id))
        if (typeof dataPayType !== 'undefined') {
          return dataPayType.text
        } else {
          return ''
        }
    },
    getOrgName  (id) {
        const dataBankData = this.$store.state.CommonService.commonObj.orgProfileList.find(item => item.value === parseInt(id))
        if (typeof dataBankData !== 'undefined') {
          return this.$i18n.locale === 'en' ? dataBankData.text_en : dataBankData.text_bn
        } else {
          return ''
        }
    },
    getBankName (id) {
        const dataBankData = this.$store.state.CommonService.commonObj.bankList.find(item => item.value === parseInt(id))
        if (typeof dataBankData !== 'undefined') {
          return this.$i18n.locale === 'en' ? dataBankData.text_en : dataBankData.text_bn
        } else {
          return ''
        }
    },
    getTrainingTitle (id) {
        const dataBankData = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTitleList.find(item => item.value === parseInt(id))
        if (typeof dataBankData !== 'undefined') {
          return this.$i18n.locale === 'en' ? dataBankData.text_en : dataBankData.text_bn
        } else {
          return ''
        }
    },
    getTrainingType (id) {
        const dataBankData = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTypeList.find(item => item.value === parseInt(id))
        if (typeof dataBankData !== 'undefined') {
          return this.$i18n.locale === 'en' ? dataBankData.text_en : dataBankData.text_bn
        } else {
          return ''
        }
    },
    getBranchName (id) {
        const dataBranchData = this.$store.state.CommonService.commonObj.branchList.find(item => item.value === parseInt(id))
        if (typeof dataBranchData !== 'undefined') {
          return this.$i18n.locale === 'en' ? dataBranchData.text_en : dataBranchData.text_bn
        } else {
          return ''
        }
    },
    async pdfExport () {
      this.customloading = true
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: this.item.org_id, circular_memo_no: this.item.circular_memo_no })
      const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, paymentReceiptApi, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.customloading = false
    }
  }
}
</script>
