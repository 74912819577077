<template>
  <div class="inner-section">
    <card>
      <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{$t('externalTraining.circular_list')}}</h4>
      </template>
      <template v-slot:searchBody>
          <b-row>
              <b-col sm="4">
                <b-form-group
                    class="row"
                    label-for="fiscal_year_id"
                >
                  <template v-slot:label>
                    {{$t('elearning_config.fiscal_year')}}
                  </template>
                  <v-select name="organization"
                    v-model="search.fiscal_year_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= fiscalYearList
                    :placeholder="$t('globalTrans.select')"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group
                    class="row"
                    :label="$t('elearning_config.organization')"
                    label-for="organization"
                >
                  <v-select name="organization"
                      v-model="search.org_id"
                      label="text"
                      :reduce="item => item.value"
                      :options= orgList
                      :placeholder="$t('globalTrans.select')"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
                  <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
              </b-col>
          </b-row>
      </template>
    </card>
    <body-card>
      <template v-slot:headerTitle>
          <h4 class="card-title">{{$t('externalTraining.circular_list')}}</h4>
      </template>
      <template v-slot:headerAction1>

      </template>
      <template v-slot:headerAction>
          <!-- <b-button variant="primary" @click="pdfExport" class="mr-2">
            {{  $t('globalTrans.export_pdf') }}
          </b-button> -->
          <!-- <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                {{  $t('globalTrans.add_new') }}
          </b-button> -->
      </template>
      <template v-slot:body>
        <select-column-check :labelData="labelData" :search="search" :columns="columns" />
        <b-overlay :show="loadingState">
          <b-row>
            <b-col md="12" class="table-responsive">
                <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                  <b-table head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                    <template v-slot:cell(index)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(type_name)="data">
                    <span class="capitalize">{{ data.item.type_name }}</span>
                    </template>
                    <template v-slot:cell(application_deadline)="data">
                      <span class="capitalize">{{ data.item.application_deadline | dateFormat }}</span>
                    </template>
                    <template v-slot:cell(training_duration)="data">
                      <span class="capitalize">{{ data.item.training_start_date | dateFormat }} {{ $t('elearning_tim.to') }} {{ data.item.training_end_date | dateFormat }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge badge-success" v-if="data.item.hasApplied === 1">{{$t('externalTraining.applied')}}</span>
                      <span class="badge badge-primary" v-else-if="data.item.hasApplied === 0 && data.item.application_deadline >= today">{{$t('globalTrans.apply')}}</span>
                      <span class="badge badge-danger" v-else>{{$t('externalTraining.application_time_over')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <b-button v-b-modal.modal-5 variant="iq-bg-success mr-1" size="sm" class="action-btn status" :title="$t('globalTrans.view')" @click="details(data.item)"><i class="ri-eye-fill"></i></b-button>
                        <b-button v-if="data.item.circular_type === 1 && app_org_type_id === 3 && data.item.hasApplied === 1 && data.item.hasPaid === 0" v-b-modal.modal-payment @click="payment(data.item)" variant=" iq-bg-success" size="sm" class="action-btn edit" :title="$t('globalTrans.payment')"><i class="ri-secure-payment-line"></i></b-button>
                        <b-button v-if="data.item.hasPaid === 1" v-b-modal.modal-payment-details @click="paymentDetails(data.item)" variant=" iq-bg-success" size="sm" class="action-btn edit" :title="$t('globalTrans.payment') + ' ' + $t('globalTrans.receipt')"><i class="ri-booklet-line"></i></b-button>
                        <template>
                          <span v-if="data.item.hasApplied === 1">
                            <b-button v-b-modal.modal-6 variant="iq-bg-info" size="sm" class="action-btn btn-info" :title="$t('externalTraining.apply_details')" @click="details(data.item)"><i class="ri-eye-fill"></i></b-button>
                          </span>
                          <span v-else-if="data.item.hasApplied === 0 && data.item.application_deadline >= today">
                            <b-button v-b-modal.modal-4 variant="iq-bg-info" size="sm" class="action-btn btn-info" :title="$t('externalTraining.apply')" @click="edit(data.item)"><i class="ri-file-add-line"></i></b-button>
                          </span>
                          <span v-else>
                              <b-button disabled variant="iq-bg-danger" size="sm" class="action-btn btn-danger" :title="$t('externalTraining.apply')"><i class="ri-file-add-line"></i></b-button>
                          </span>
                        </template>
                    </template>
                  </b-table>
                </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- <pre>{{ listData }}</pre> -->
    </body-card>
    <b-modal id="modal-4" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-5" size="lg" hide-footer :title="$t('elearning_iabm.circular_publication_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :item="item" :key="id" ref="details"/>
    </b-modal>
    <b-modal id="modal-6" size="lg" hide-footer :title="$t('externalTraining.apply_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <DetailsApplication :item="item" :key="id" ref="details"/>
    </b-modal>
    <b-modal id="modal-payment" size="lg" :title="$t('globalTrans.payment') + ' ' + $t('globalTrans.details')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Payment :id="detailsItemId" :item="item"/>
    </b-modal>
    <b-modal id="modal-payment-details" size="lg" :title="$t('globalTrans.payment') + ' ' + $t('globalTrans.receipt')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <template #modal-title>
        {{ $t('externalTradeFair.fair_participation_payment') + ' ' + $t('globalTrans.details') }}
        <b-button variant="primary" @click="pdfExportPayment" class="mr-2 float-right">
          {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <PaymentDetails :id="payDetailsItemId" :item="item" ref="details"/>
    </b-modal>
  </div>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { circularPublicationToggleStatus, trainingAppliedList, profileInfo, trainingEkpayMessage } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Form from './Form'
import Details from './Details'
import Payment from './payment/PaymentModal'
import PaymentDetails from './payment/PaymentDetailsModal'
import DetailsApplication from './DetailsApplication'
import { mapGetters } from 'vuex'
// import moment from 'moment'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details, DetailsApplication, Payment, PaymentDetails
  },
  data () {
    return {
      search: {
        org_id: [],
        fiscal_year_id: [],
        limit: 20
      },
      detailsItemId: '',
      app_org_type_id: '',
      payDetailsItemId: 0,
      paid: [],
      certiificateList: [],
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
        { labels: 'elearning_iabm.circular_memo_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '30%' } },
        { labels: 'elearning_config.fiscal_year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '35%' } },
        { labels: 'elearning_config.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '35%' } },
        { labels: 'elearning_config.training_title', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '35%' } },
        { labels: 'teaGardenPduService.application_deadline', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '35%' } },
        { labels: 'elearning_iabm.training_duration', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '35%' } },
        { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 9, thStyle: { width: '15%' } }
      ],
      today: new Date().toISOString().split('T')[0],
      id: 0,
      item: '',
      appliedList: []
    }
  },
  async created () {
    this.search = Object.assign({}, this.search, {
      fiscal_year_id: this.$store.state.currentFiscalYearId
    })
    this.profileCheck()
    this.paymentStatusUpdate()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    ...mapGetters({
        authUser: 'Auth/authUser'
    }),
    accommodationList () {
      const accommodations = [
          { text: this.$i18n.locale === 'en' ? 'With Accommodation' : 'থাকার ব্যবস্থা সহ', text_en: 'With Accommodation', text_bn: 'থাকার ব্যবস্থা সহ', value: 1 },
          { text: this.$i18n.locale === 'en' ? 'Without Accommodation' : 'থাকার ব্যবস্থা ছাড়া', text_en: 'Without Accommodation', text_bn: 'থাকার ব্যবস্থা ছাড়া', value: 2 }
      ]
      return accommodations
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    orgList: function () {
      // if (this.$store.state.ExternalUserService.trainingPanel.trainerOrTrainee) {
      //   return this.$store.state.CommonService.commonObj.orgProfileList.filter(item => item.status === 1 && (item.value === 12 || item.value === this.$store.state.ExternalUserService.trainingPanel.trainerOrTrainee.professional_info.org_id))
      // } else {
      //   return this.$store.state.CommonService.commonObj.orgProfileList.filter(item => item.status === 1 && item.value === 12)
      // }
        return this.$store.state.CommonService.commonObj.orgProfileList.filter(item => item.status === 1)
    },
    trainerEvaluationList: function () {
      return this.$store.state.ExternalUserService.trainingPanel.commonObj.trainerEvaluationList.filter(item => item.status === 1)
    },
    formTitle () {
        return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('externalTraining.training_application')
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
          keys = [
          { key: 'serial' },
          { key: 'circular_memo_no' },
          { key: 'fiscal_year_bn' },
          { key: 'org_bn' },
          { key: 'training_title_bn' },
          { key: 'application_deadline' },
          { key: 'training_duration' },
          { key: 'status' },
          { key: 'action' }
          ]
      } else {
          keys = [
          { key: 'serial' },
          { key: 'circular_memo_no' },
          { key: 'fiscal_year' },
          { key: 'org' },
          { key: 'training_title' },
          { key: 'application_deadline' },
          { key: 'training_duration' },
          { key: 'status' },
          { key: 'action' }
          ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  mounted () {
  },
  methods: {
    profileCheck () {
      RestApi.getData(trainingElearningServiceBaseUrl, `${profileInfo}`).then(response => {
        if (response.success) {
          if (response.data.status !== 3) {
            this.$router.push('/training-panel/profile')
          }
        } else {
          this.$router.push('/training-panel/profile')
        }
      })
    },
    async paymentStatusUpdate () {
      if (this.$route.params.status && this.$route.query.transId) {
        const status = this.$route.params.status
        const tranId = this.$route.query.transId
        await RestApi.getData(trainingElearningServiceBaseUrl, trainingEkpayMessage + status, { transId: tranId }).then(response => {
          if (response.success) {
            this.$toast.success({
              title: 'Success',
              message: response.message,
              color: '#D6E09B'
            })
          } else {
            this.$toast.error({
              title: 'Error',
              message: response.message
            })
          }
        })
        this.loadData()
        this.$router.push('/training-panel/circular-list')
      } else {
        this.loadData()
      }
    },
    // async appPaymentData () {
    //   this.loader = true
    //     const params = {
    //          user_id: this.$store.state.Auth.authUser.user_id
    //     }
    //     const result = await RestApi.getData(trainingElearningServiceBaseUrl, paymentDetailsApi, params)
    //     this.loader = false
    //     if (result.success) {
    //         this.paid = 1
    //     } else {
    //       this.paid = 0
    //     }
    // },
    async appliedListData () {
      RestApi.getData(trainingElearningServiceBaseUrl, trainingAppliedList, '').then(response => {
        if (parseInt(response.model.status) === 1) {
          this.$router.push('/training-panel/dashboard')
        }
        if (response.success) {
          this.appliedList = response.data
        } else {
          this.appliedList = []
          if (!response.profileComplete) {
            this.$router.push('/training-panel/profile')
          }
        }
      })
    },
    dataChange () {
        this.loadData()
    },
    details (item) {
        this.item = item
    },
    payment (item) {
      this.detailsItemId = item.id
      this.item = item
    },
    paymentDetails (item) {
      this.payDetailsItemId = item.id
      this.item = item
    },
    async searchData () {
      this.loadData()
    },
    remove (item) {
        this.changeStatus(trainingElearningServiceBaseUrl, circularPublicationToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      // this.appliedListData()
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage, user_type: this.$store.state.ExternalUserService.trainingPanel.panel })
      RestApi.getData(trainingElearningServiceBaseUrl, 'external-user/circular-publications', params).then(response => {
        if (parseInt(response.model.status) === 1) {
          this.$router.push('/training-panel/dashboard')
        }
        if (response.success) {
          this.appliedList = response.applyData
          this.app_org_type_id = response.model.professional_info.org_type_id
          this.paid = response.payment
          this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
          this.paginationData(response.data, this.search.limit)
        } else {
          this.appliedList = []
          if (!response.profileComplete) {
            this.$router.push('/training-panel/profile')
          }
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getCustomDataList (data) {
      const listData = data.map((item, index) => {
        const applied = this.appliedList.find(list => String(list.circular_memo_no) === String(item.circular_memo_no))
        const applyData = {}
        if (typeof applied === 'undefined') {
          applyData.hasApplied = 0
          applyData.application_id = 0
          applyData.accommodation_type = ''
          applyData.accommodation_type_bn = ''
        } else {
          applyData.hasApplied = 1
          applyData.application_id = applied.application_id
        }
        const paid = this.paid.find(list => String(list.circular_memo_no) === String(item.circular_memo_no))
        const paidData = {}
        if (typeof paid === 'undefined') {
          paidData.hasPaid = 0
        } else {
          paidData.hasPaid = 1
        }
        const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(doc => doc.value === parseInt(item.org_id))
        const orgData = {}
        if (typeof orgObj !== 'undefined') {
          orgData.org = orgObj.text_en
          orgData.org_bn = orgObj.text_bn
        } else {
          orgData.org = ''
          orgData.org_bn = ''
        }

        const officeObj = this.$store.state.CommonService.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
        const officeData = {}
        if (typeof officeObj !== 'undefined') {
          officeData.office = officeObj.text_en
          officeData.office_bn = officeObj.text_bn
        } else {
          officeData.office = ''
          officeData.office_bn = ''
        }
        const officeTypeObj = this.$store.state.CommonService.commonObj.officeTypeList.find(doc => doc.value === parseInt(item.office_type_id))
        const officeTypeData = {}
        if (typeof officeTypeObj !== 'undefined') {
          officeTypeData.office_type = officeTypeObj.text_en
          officeTypeData.office_type_bn = officeTypeObj.text_bn
        } else {
          officeTypeData.office_type = ''
          officeTypeData.office_type_bn = ''
        }

        const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
        const fiscalYearData = {}
        if (typeof fiscalYearObj !== 'undefined') {
          fiscalYearData.fiscal_year = fiscalYearObj.text_en
          fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          fiscalYearData.fiscal_year = ''
          fiscalYearData.fiscal_year_bn = ''
        }
        const trainingTitleObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
        const trainingTitleData = {}
        if (typeof trainingTitleObj !== 'undefined') {
          trainingTitleData.training_title = trainingTitleObj.text_en
          trainingTitleData.training_title_bn = trainingTitleObj.text_bn
        } else {
          trainingTitleData.training_title = ''
          trainingTitleData.training_title_bn = ''
        }
        const trainingTypeObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
        const trainingTypeData = {}
        if (typeof trainingTypeObj !== 'undefined') {
          trainingTypeData.training_type = trainingTypeObj.text_en
          trainingTypeData.training_type_bn = trainingTypeObj.text_bn
        } else {
          trainingTypeData.training_type = ''
          trainingTypeData.training_type_bn = ''
        }
        const trainingCategoryObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
        const trainingCategoryData = {}
        if (typeof trainingCategoryObj !== 'undefined') {
          trainingCategoryData.training_category = trainingCategoryObj.text_en
          trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
        } else {
          trainingCategoryData.training_category = ''
          trainingCategoryData.training_category_bn = ''
        }

        return Object.assign({}, item, { serial: index + 1 }, orgData, officeData, officeTypeData, fiscalYearData, trainingTitleData, applyData, trainingTypeData, trainingCategoryData, paidData)
      })
      return listData
    },
    pdfExportPayment () {
      this.$refs.details.pdfExport()
    }
  }
}
</script>
