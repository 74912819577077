<template>
  <b-overlay :show="loading">
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <b-form @submit.prevent="handleSubmit(createData)" @reset.prevent="reset">
        <b-row>
          <b-col sm="6">
            <ValidationProvider name="Registration" vid='circular_memo_no' rules="required">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="circular_memo_no"
                  slot-scope="{ valid, errors }"
              >
                  <template v-slot:label>
                      {{ $t('externalTraining.circular_memo_no') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    disabled
                    id="circular_memo_no"
                    v-model="formData.circular_memo_no"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- <b-col sm="6" v-if="$i18n.locale === 'en'">
            <ValidationProvider name="Name (En)" vid='name' rules="">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="name"
                  slot-scope="{ valid, errors }"
              >
                  <template v-slot:label>
                      {{ $t('globalTrans.name')}}
                  </template>
                  <b-form-input
                    id="name"
                    disabled
                    v-model="formData.name"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
              </b-form-group>
            </ValidationProvider>
          </b-col> -->
          <b-col sm="6"></b-col>
          <b-col sm="6">
            <ValidationProvider name="Name (En)"  vid="name" rules="">
              <b-form-group
                  class="row"
                  label-for="name"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('globalTrans.name')}}
                </template>
                <b-form-textarea
                    rows="2"
                    id="name"
                    v-model="formData.name"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-textarea>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
            <ValidationProvider name="Name (Bn)"  vid="name_bn" rules="">
              <b-form-group
                  class="row"
                  label-for="name_bn"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('globalTrans.name')}}
                </template>
                <b-form-textarea
                    rows="2"
                    id="name_bn"
                    v-model="formData.name_bn"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-textarea>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- <b-col sm="6" v-if="$i18n.locale === 'bn'">
            <ValidationProvider name="Name (Bn)" vid='name' rules="">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="name"
                  slot-scope="{ valid, errors }"
              >
                  <template v-slot:label>
                      {{ $t('globalTrans.name')}}
                  </template>
                  <b-form-input
                    id="name"
                    disabled
                    v-model="formData.name_bn"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
              </b-form-group>
            </ValidationProvider>
          </b-col> -->
          <b-col sm="6">
            <ValidationProvider name="NID" vid='nid' rules="">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="nid"
                  slot-scope="{ valid, errors }"
              >
                  <template v-slot:label>
                      {{ $t('globalTrans.nid')}}
                  </template>
                  <b-form-input
                    disabled
                    type="number"
                    id="nid"
                    v-model="formData.nid"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
            <ValidationProvider name="Date Of Birth" vid="date_of_birth" rules="">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="date_of_birth"
                  slot-scope="{ valid, errors }"
              >
                  <template v-slot:label>
                  {{$t('globalTrans.dob')}}
                  </template>
                  <b-form-input
                  disabled
                  class="fromDate"
                  v-model="formData.date_of_birth"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
            <ValidationProvider name="Email" vid='email' rules="">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="email"
                  slot-scope="{ valid, errors }"
              >
                  <template v-slot:label>
                      {{ $t('externalTraining.email')}}
                  </template>
                  <b-form-input
                    disabled
                    id="email"
                    v-model="formData.email"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
            <ValidationProvider name="Training Type"  vid="training_type_id" rules="">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="training_type_id"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_config.training_type')}}
                </template>
                <b-form-select
                  disabled
                  plain
                  v-model="formData.training_type_id"
                  :options="trainingTypeList"
                  id="training_type_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
            <ValidationProvider name="Training Title" vid="training_title_id">
              <b-form-group
                class="row"
                label-cols-sm="12"
                label-for="training_title_id"
                slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_config.training_title')}}
                </template>
                <b-form-select
                  disabled
                  plain
                  v-model="formData.training_title_id"
                  :options="trainingTitleList"
                  id="training_title_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
            <ValidationProvider name="Organization Type"  vid="org_type_id" rules="">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="org_type_id"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_config.organization_type')}}
                </template>
                <b-form-select
                  disabled
                  plain
                  v-model="formData.org_type_id"
                  :options="orgTypeList"
                  id="org_type_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6" v-if="formData.org_type_id !== 3">
            <ValidationProvider name="Organization"  vid="org_id" rules="">
              <b-form-group
                class="row"
                label-cols-sm="12"
                label-for="org_id"
                slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_config.organization')}}
                </template>
                <b-form-select
                  disabled
                  plain
                  v-model="formData.org_id"
                  :options="orgList"
                  id="org_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- <b-col sm="6" v-if="$i18n.locale === 'en' && formData.org_type_id == 3">
            <ValidationProvider name="Organization" vid='organization' rules="">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="organization"
                  slot-scope="{ valid, errors }"
              >
                  <template v-slot:label>
                      {{ $t('globalTrans.organization')}}
                  </template>
                  <b-form-input
                    id="organization"
                    disabled
                    :v-model="formData.org"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6" v-if="$i18n.locale === 'bn' && formData.org_type_id == 3">
            <ValidationProvider name="Organization" vid='organization' rules="">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="organization"
                  slot-scope="{ valid, errors }"
              >
                  <template v-slot:label>
                      {{ $t('globalTrans.organization')}}
                  </template>
                  <b-form-input
                    id="organization"
                    disabled
                    :v-model="formData.org_bn"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
              </b-form-group>
            </ValidationProvider>
          </b-col> -->
          <b-col sm="6">
            <ValidationProvider name="Organization (En)"  vid="org" rules="">
              <b-form-group
                  class="row"
                  label-for="org"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('globalTrans.organization')}}
                </template>
                <b-form-textarea
                    rows="2"
                    id="org"
                    v-model="formData.org"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-textarea>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
            <ValidationProvider name="Organization (Bn)"  vid="org_bn" rules="">
              <b-form-group
                  class="row"
                  label-for="org_bn"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('globalTrans.organization')}}
                </template>
                <b-form-textarea
                    rows="2"
                    id="org_bn"
                    v-model="formData.org_bn"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-textarea>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6" v-if="formData.org_type_id !== 3">
            <ValidationProvider name="Attachment" vid="attachment" v-slot="{ errors }" rules="required">
              <b-form-group
                class="row"
                label-for="attachment"
                label-cols-sm="12"
              >
              <template v-slot:label>
                {{ $t('globalTrans.attachment') }} <span class="text-danger">*</span>
              </template>
              <b-input-group>
                <b-form-file
                  validate="size:10"
                  id="attachment"
                  v-model="attachment_input"
                  @change="onChange"
                  :state="errors[0] ? false : (valid ? true : null)"
                  placeholder="Choose a file or drop it here"
                  drop-placeholder="Drop file here..."
                ></b-form-file>
              </b-input-group>
              <!-- <div v-if="data.logo">
                <img :src="rjscServiceBaseUrl + 'storage/' + form.logo" style="width: 40px; height: 40px; border-radius: 50% !important;" class="center">
              </div> -->
              <div class="invalid-feedback d-block">
                {{ errors[0] }}
              </div>
              <div class="invalid-feedback d-block text-muted">
                  {{ $i18n.locale == 'en' ? '[Maximum file size is 2 MB and file format is JPG/JPEG/PDF]' : '[সর্বোচ্চ ফাইল সাইজ 2 MB এবং ফাইল ফরমেট JPG/JPEG/PDF]' }}
              </div>
              <!-- <div class="d-block invalid-feedback">
                {{ fileValidationMsz }}
              </div> -->
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
            <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
              <b-form-group
                  class="row"
                  label-for="remarks"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_config.remarks_en')}}
                </template>
                <b-form-textarea
                    rows="2"
                    id="remarks"
                    v-model="formData.remarks"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-textarea>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
            <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
              <b-form-group
                  class="row"
                  label-for="remarks_bn"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_config.remarks_bn')}}
                </template>
                <b-form-textarea
                    rows="2"
                    id="remarks_bn"
                    v-model="formData.remarks_bn"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-textarea>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <div class="text-right my-2">
          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
          <b-button variant="danger" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
        </div>
      </b-form>
    </ValidationObserver>
  </b-overlay>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingApplicationStore, profileInfo } from '../../api/routes'
import flatpickr from 'flatpickr'
import { mapGetters } from 'vuex'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    const tmp = this.getFormData()
    this.getUserInfo(tmp)
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('externalTraining.apply') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        mobile: '',
        training_type_id: 0,
        training_category_id: 0,
        training_title_id: 0,
        date_of_birth: '',
        org_type_id: 0,
        org_id: 0,
        nid: '',
        attachment: '',
        org: '',
        org_bn: '',
        name: '',
        name_bn: '',
        email: '',
        circular_memo_no: '',
        remarks: '',
        remarks_bn: '',
        status: 1
      },
      fileValidationMsz: '',
      options: [
        { text: this.$i18n.locale === 'en' ? 'With Accommodation' : 'থাকার ব্যবস্থা সহ', value: '1' },
        { text: this.$i18n.locale === 'en' ? 'Without Accommodation' : 'থাকার ব্যবস্থা ছাড়া', value: '2' }
      ],
      officeTypeList: [],
      officeList: [],
      trainingCategoryList: [],
      loading: false,
      trainingTitleList: [],
      attachment_input: []
    }
  },
  computed: {
    orgTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'এমওসি' : 'MOC',
          text_en: 'MOC',
          text_bn: 'এমওসি'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'ব্যক্তিগত' : 'Private',
          text_en: 'Private',
          text_bn: 'ব্যক্তিগত'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'অন্যান্য' : 'Others',
          text_en: 'Others',
          text_bn: 'অন্যান্য'
        }
      ]
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.orgProfileList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    ...mapGetters({
      authUser: 'Auth/authUser'
    })
  },
  watch: {
    'formData.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    }
  },
  methods: {
    getUserInfo (tmp) {
      this.loading = true
      RestApi.getData(trainingElearningServiceBaseUrl, `${profileInfo}`).then(response => {
        if (response.success) {
          tmp.org_type_id = response.data.professional_info.org_type_id
          tmp.org_id = response.data.professional_info.org_id
          tmp.org = response.data.professional_info.org_name_en
          tmp.org_en = response.data.professional_info.org_name_bn
          tmp.date_of_birth = response.data.date_of_birth
          tmp.nid = response.data.nid
          tmp.name = response.data.name
          tmp.name_bn = response.data.name_bn
          tmp.email = response.data.email
          tmp.mobile = response.data.mobile
          tmp.registration_for = this.$store.state.ExternalUserService.trainingPanel.panel
        } else {
          tmp.date_of_birth = ''
          tmp.nid = ''
          tmp.name = ''
          tmp.name_bn = ''
          tmp.org = ''
          tmp.org_en = ''
          tmp.email = ''
          tmp.mobile = ''
          tmp.registration_for = 0
        }
        this.formData = tmp
        this.loading = false
      })
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_type_id === categoryId)
      }
      return trainingTitleList
    },
    onChange (e) {
      this.fileValidationMsz = ''
      const input = e.target
      const file = input.files[0]
      if (file.size > 1024 * 2048) {
        e.preventDefault()
        this.fileValidationMsz = 'Maximum file size must be 2MB'
      }
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.formData.attachment = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      } else {
        this.formData.attachment = ''
      }
    },
    async createData () {
      this.loading = true
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.formData.id) {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingApplicationStore, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingApplicationStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        // this.$socket.emit('send-notification', result.notification)
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
