<template>
  <b-container fluid>
    <div>
      <b-overlay :show="loading">
        <!-- <b-row>
          <b-col>
              <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="circular.org_id">
                  <div class="titleDiv">
                    <span class="text-center text-dark reportTitle" style="font-weight:bold;">{{ $t('externalTraining.apply_details') }}</span><br>
                  </div>
              </list-report-head>
          </b-col>
        </b-row> -->
        <b-col lg="12">
          <b-row>
            <b-col lg="12" sm="12" class="p-0">
                <b-table-simple bordered v-if="circular">
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('externalTraining.circular_memo_no') }}</b-th>
                      <b-td style="width:65%%;" class="text-left">{{ circular.circular_memo_no }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_config.fiscal_year') }}</b-th>
                      <b-td style="width:65%%;" class="text-left bg-light">{{ ($i18n.locale=='bn') ? fiscal_year_bn : fiscal_year }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('globalTrans.organization') }}</b-th>
                      <b-td style="width:65%%;" class="text-left">{{ ($i18n.locale=='bn') ? org_bn : org }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_config.organization_type') }}</b-th>
                      <b-td style="width:65%%;" class="text-left">{{ getOrgType(circular.org_type_id) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_config.training_type') }}</b-th>
                      <b-td style="width:65%%;" class="text-left bg-light">{{ ($i18n.locale=='bn') ? training_type_bn : training_type }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_config.training_title') }}</b-th>
                      <b-td style="width:65%%;" class="text-left bg-light">{{ ($i18n.locale=='bn') ? training_title_bn : training_title }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('globalTrans.remarks') }}</b-th>
                      <b-td style="width:65%%;" class="text-left">{{ ($i18n.locale=='bn') ? circular.remarks_bn : circular.remarks }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('globalTrans.attachment') }}</b-th>
                      <b-td style="width:65%%;" class="text-left">
                        <a v-if="circular.attachment" target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/' + circular.attachment" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                      </b-td>
                    </b-tr>
                </b-table-simple>
            </b-col>
          </b-row>
          <!-- <pre>{{ circular }}</pre> -->
        </b-col>
      </b-overlay>
    </div>
  </b-container>
</template>
<style scoped>
.table-form th, td{
  padding: 3px !important;
  margin: 1px !important;
  vertical-align: middle !important;
  font-size: 15px;
}
</style>
<script>
// import ExportPdf from './export-pdf_details'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingApplicationShow } from '../../api/routes'
// import ListReportHead from '@/components/custom/ExternalListReportHead.vue'

export default {
    components: {
        // ListReportHead
    },
    name: 'FormLayout',
    props: ['item'],
    created () {
      this.appliedListData()
    },
    data () {
      return {
        loading: false,
        circular: '',
        fiscal_year: '',
        fiscal_year_bn: '',
        org: '',
        org_bn: '',
        training_title: '',
        training_title_bn: '',
        training_type: '',
        training_type_bn: '',
        slOffset: 1,
        trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
      }
    },
    computed: {
      orgTypeList: function () {
        return [
          {
            value: 1,
            text: this.$i18n.locale === 'bn' ? 'এমওসি' : 'MOC',
            text_en: 'MOC',
            text_bn: 'এমওসি'
          },
          {
            value: 2,
            text: this.$i18n.locale === 'bn' ? 'ব্যক্তিগত' : 'Private',
            text_en: 'Private',
            text_bn: 'ব্যক্তিগত'
          },
          {
            value: 3,
            text: this.$i18n.locale === 'bn' ? 'অন্যান্য' : 'Others',
            text_en: 'Others',
            text_bn: 'অন্যান্য'
          }
        ]
      }
    },
    methods: {
      async appliedListData () {
        this.loading = true
        const params = {
          circular_memo_no: this.item.circular_memo_no
        }
        RestApi.getData(trainingElearningServiceBaseUrl, trainingApplicationShow, params).then(response => {
          if (response.success) {
            this.circular = response.data
            const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(doc => doc.value === parseInt(this.circular.org_id))
            if (typeof orgObj !== 'undefined') {
              this.org = orgObj.text_en
              this.org_bn = orgObj.text_bn
            } else {
              this.org = ''
              this.org_bn = ''
            }
            const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(this.circular.fiscal_year_id))
            if (typeof fiscalYearObj !== 'undefined') {
              this.fiscal_year = fiscalYearObj.text_en
              this.fiscal_year_bn = fiscalYearObj.text_bn
            } else {
              this.fiscal_year = ''
              this.fiscal_year_bn = ''
            }
            const trainingTitleObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTitleList.find(doc => doc.value === parseInt(this.circular.training_title_id))
            if (typeof trainingTitleObj !== 'undefined') {
              this.training_title = trainingTitleObj.text_en
              this.training_title_bn = trainingTitleObj.text_bn
            } else {
              this.training_title = ''
              this.training_title_bn = ''
            }
            const trainingTypeObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTypeList.find(doc => doc.value === parseInt(this.circular.training_type_id))
            if (typeof trainingTypeObj !== 'undefined') {
              this.training_type = trainingTypeObj.text_en
              this.training_type_bn = trainingTypeObj.text_bn
            } else {
              this.training_type = ''
              this.training_type_bn = ''
            }
            this.loading = false
          } else {
            this.loading = false
            this.circular = ''
          }
        })
      },
      getOrgType (orgType) {
        const orgId = this.orgTypeList.find(obj => obj.value === orgType)
        return orgId.text
      }
    }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  .reportTitle {
        font-weight: bold;
        border: 1px solid;
        padding: 8px 15px 8px 15px;
        border-radius: 11px;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
